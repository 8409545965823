
/* 
  
  .slick-list {
    overflow: hidden;
    will-change: transform;
  } */
  
   .slick-slide {
    padding: 10px;
   
    
   }
/*   
  .slick-slide img {
    
    width: 400px;
    margin: 0 auto;
    object-fit: cover;
  } */ 
  
  /* [type="button"] {
    position: fixed;
    top: 50%;
    z-index: 10;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border: 0;
    text-indent: -9999px;
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 0.9;
    transform: translateY(-50%);
    transition: all 0.25s ease;
  } */
  
